.navbarContactStyle{
    background-image:linear-gradient(to left, rgb(8, 8, 8),rgb(8, 8, 8),transparent,transparent, transparent,transparent, transparent);
    position: relative;
    width:100%;
    color:white;
    display: flex;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    justify-content: center;
    .navbar-phoneNumber{
        position: relative;
        width:100%;
        display: flex;
        max-width: 2200px;
        align-items: center;
        justify-content: flex-end;
        p{
            position: relative;
            right:5.7%;
            font-size: 18px;
            padding-bottom: 5px;
           }
    }
    @media screen and (max-width: 700px){
        display:none;
    }
}
